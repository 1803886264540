// Import the functions you need from the SDKs you need

import {initializeApp} from 'firebase/app';

import {getDatabase} from 'firebase/database';

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: 'AIzaSyD0O9GIbCB1jOeG4SBSxaibqdeWm6zY0Ls',
  authDomain: 'bezoek-registratie-fransen.firebaseapp.com',
  databaseURL: 'https://bezoek-registratie-fransen-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bezoek-registratie-fransen',
  storageBucket: 'bezoek-registratie-fransen.appspot.com',
  messagingSenderId: '59855850273',
  appId: '1:59855850273:web:22375ee298ff6fab0c4c60',
};

// Initialize Firebase

const app = initializeApp (firebaseConfig);

export const db = getDatabase (
  app,
  'https://bezoek-registratie-fransen-default-rtdb.europe-west1.firebasedatabase.app'
);

import "./App.css";
import { Container, Grid, Paper, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import EventIcon from "@mui/icons-material/Event";
import EditIcon from "@mui/icons-material/Edit";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import NotesIcon from "@mui/icons-material/SpeakerNotes";
import AnnouncementIcon from "@mui/icons-material/Announcement";
// import { db } from "./db";
import { months, days } from "./months";
import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { db } from "./firebase.config";
import { set, ref, onValue } from "firebase/database";

const toKey = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
function write({
  morning = "",
  evening = "",
  afternoon = "",
  night = "",
  date,
  id,
  note = "",
}) {
  set(ref(db, `days/${toKey(date)}`), {
    morning,
    evening,
    afternoon,
    night,
    date: String(date.getTime()),
    id: String(toKey(date)),
    note: String(note || ""),
  });
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: 22,
  color: theme.palette.text.secondary,
}));

const Field = ({ value, onClick }) => {
  if (value && value.length) {
    return (
      <Button
        variant="outlined"
        endIcon={<EditIcon />}
        onClick={onClick}
        size="small"
        style={{
          maxWidth: "90%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Button>
    );
  }
  return (
    <Button variant="contained" onClick={onClick}>
      <EditIcon />
    </Button>
  );
};

const App = () => {
  const [fields, setFields] = useState([]);
  const [dialog, setDialog] = useState(null);
  const [dialogNotes, setDialogNotes] = useState(null);
  const [note, setNote] = useState("");
  const [text, setText] = useState("");
  const [date, setDate] = useState(new Date());
  const [showNewDate, setShowNewDate] = useState(false);

  const [filter, setFilter] = useState(new Date());

  useEffect(() => {
    const f = new Date();
    f.setDate(f.getDate() - 4);
    setFilter(f);
    onValue(ref(db, "days"), (snapshot) => {
      if (snapshot.exists()) {
        setFields(
          Object.values(snapshot.val()).map((v) => ({
            ...v,
            date: new Date(+v.date),
          }))
        );
      } else {
        console.log("No data available");
      }
    });
  }, []);

  const onSave = () => {
    write({
      ...dialog.record,
      [dialog.slot]: text,
    });
    setText("");
    alert("Ingeschreven");
    setDialog(false);
  };

  const onSaveNote = () => {
    write({
      ...dialogNotes,
      note,
    });
    setNote("");
    alert("Aangepast");
    setDialogNotes(false);
  };

  const addDate = () => {
    if (fields.find((f) => toKey(new Date(date)) === toKey(f.date))) {
      alert("Deze dag is al aanwezig");
      return;
    }
    write({ afternoon: "", evening: "", date: new Date(date) });
    alert("Aangemaakt");
    setShowNewDate(false);
  };

  return (
    <Container>
      <Grid container direction="column">
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h4" style={{ color: "#403F4C" }}>
              <UpcomingIcon />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" style={{ color: "#403F4C" }}>
              Bezoek Registratie
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" style={{ color: "#403F4C" }}>
              
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={12}
          component={Item}
          justifyContent="space-between"
          spacing={2}
          style={{
            marginTop: 5,
            padding: 5,
            paddingBottom: 15,
            marginLeft: -8,
          }}
        >
          <Grid item xs={12}>
            <Typography
              style={{ verticalAlign: "middle", display: "inline-flex" }}
            >
              <EventIcon />
              Datum
            </Typography>
          </Grid>
          <Grid xs={6} sm={3} item>
            <Typography variant="h6">Ochtend</Typography>
          </Grid>
          <Grid xs={6} sm={3} item>
            <Typography variant="h6">Middag</Typography>
          </Grid>
          <Grid xs={6} sm={3} item>
            <Typography variant="h6">Avond</Typography>
          </Grid>
          <Grid xs={6} sm={3} item>
            <Typography variant="h6">Nacht</Typography>
          </Grid>
        </Grid>
        {fields
          .filter(({ date }) => date >= filter)
          .sort((a, b) => (a.date > b.date ? 1 : -1))
          .map((record) => (
            <Grid
              key={record.id}
              item
              container
              direction="row"
              xs={12}
              component={Item}
              justifyContent="space-between"
              spacing={2}
              style={{
                marginTop: 5,
                padding: 5,
                paddingBottom: 15,
                marginLeft: -8,
              }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{ verticalAlign: "middle", display: "inline-flex" }}
                >
                  <EventIcon />
                  {days[record.date.getDay()]} {record.date.getDate()}{" "}
                  {months[record.date.getMonth()]}
                </Typography>
              </Grid>
              <Grid xs={6} sm={3} item>
                <Field
                  value={record.morning}
                  onClick={() => {
                    setDialog({ slot: "morning", record });
                    setText(record.morning || "");
                  }}
                />
              </Grid>
              <Grid xs={6} sm={3} item>
                <Field
                  value={record.afternoon}
                  onClick={() => {
                    setDialog({ slot: "afternoon", record });
                    setText(record.afternoon || "");
                  }}
                />
              </Grid>
              <Grid xs={6} sm={3} item>
                <Field
                  value={record.evening}
                  onClick={() => {
                    setDialog({ slot: "evening", record });
                    setText(record.evening || "");
                  }}
                />
              </Grid>
              <Grid xs={6} sm={3} item>
                <Field
                  value={record.night}
                  onClick={() => {
                    setDialog({ slot: "night", record });
                    setText(record.night || "");
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                item
                container
                alignIems="center"
                justifyContent="center"
              >
                <Grid item>
                  <Button
                    onClick={() => {
                      setDialogNotes(record);
                      setNote(record.note || "");
                    }}
                    startIcon={
                      record?.note?.length ? (
                        <AnnouncementIcon />
                      ) : (
                        <NotesIcon />
                      )
                    }
                    variant="outlined"
                    color={record?.note?.length ? "secondary" : "primary"}
                  >
                    Aantekeningen
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        <Grid
          item
          container
          direction="column"
          xs={12}
          component={Item}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{
            marginTop: 5,
            padding: 5,
            paddingBottom: 15,
            marginLeft: -8,
          }}
        >
          <Grid item xs={4}>
            <Button onClick={() => setShowNewDate(true)} variant="outlined">
              Dag Toevoegen
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={dialog?.record} onClose={setDialog}>
        <DialogTitle>
          Inschrijven voor {days[dialog?.record?.date.getDay()]}{" "}
          {dialog?.record?.date.getDate()}{" "}
          {months[dialog?.record?.date.getMonth()]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul je naam / namen in en klik op opslaan.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Naam"
            type="text"
            fullWidth
            variant="standard"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setDialog}>Cancel</Button>
          <Button onClick={onSave}>Opslaan</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogNotes?.id} onClose={setDialogNotes}>
        <DialogTitle>
          Notities aanpassen voor {days[dialogNotes?.date?.getDay()]}{" "}
          {dialogNotes?.date?.getDate()} {months[dialogNotes?.date?.getMonth()]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul notites aan door het bericht te bewerken en op opslaan te
            klikken
          </DialogContentText>
          <TextField
            multiline
            autoFocus
            margin="dense"
            id="notes"
            label="Aantekeningen"
            type="text"
            fullWidth
            variant="standard"
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setDialogNotes}>Cancel</Button>
          <Button onClick={onSaveNote}>Opslaan</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showNewDate} onClose={() => setShowNewDate(false)}>
        <DialogTitle>Voeg nieuwe datum toe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Selecteer een datum en klik op opslaan
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            label="Datum"
            type="date"
            fullWidth
            variant="standard"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNewDate(false)}>Cancel</Button>
          <Button onClick={addDate}>Opslaan</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default App;
